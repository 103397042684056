import React, { FC, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { loginSelectors } from "src/state/selectors";
import { AUTH_REQUIRED_LINKS } from "src/ui/navigation/links";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import useFilterMenuLinks from "ui/hooks/useFilterMenuLinks";
import { legalLinks } from "ui/newUi/commonLinks";

const LegalLinks: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);

  const links = useFilterMenuLinks(legalLinks);

  const onClick = useCallback(
    (link: string) => () => {
      dispatch(
        openLoginView({
          onLoginSuccess: () => history.push(link),
        })
      );
    },
    []
  );

  return (
    <>
      {links.map(({ link, label, external }) =>
        !isLoggedIn && AUTH_REQUIRED_LINKS.includes(link) ? (
          <DropdownListItem
            key={link}
            titleMessage={label}
            external={external}
            onClick={onClick(link)}
          />
        ) : (
          <DropdownListItem
            key={link}
            titleMessage={label}
            external={external}
            to={link}
          />
        )
      )}
    </>
  );
};

export default memo(LegalLinks);
