import { defineMessages } from "react-intl";
export const sharedMessages = defineMessages({
  tango: {
    id: "tango",
    defaultMessage: "Tango",
  },
  viralityTitle: {
    id: "virality.title",
    defaultMessage: "Earn cash for free!",
  },
  login: {
    id: "signin",
    defaultMessage: "Sign in",
  },
  loginSuccessMessage: {
    id: "signin.success.message",
    defaultMessage: "You’ve now logged in",
  },
  logout: {
    id: "header.dropdown.logout",
    defaultMessage: "Log Out",
  },
  logoutSuccessMessage: {
    id: "logout.success.message",
    defaultMessage: "You’ve been logged out",
  },
  oops: {
    id: "oops",
    defaultMessage: "Oops!",
  },
  retry: {
    id: "retry",
    defaultMessage: "Retry",
  },
  loading: {
    id: "loading",
    defaultMessage: "Loading",
  },
  new: {
    id: "new",
    defaultMessage: "NEW",
  },
  searchPlaceholder: {
    id: "userSearch.placeholder",
    defaultMessage: "Search",
  },
  noSearchQueryHeader: {
    id: "userSearch.noSearchQueryHeader",
    defaultMessage: "Who are you looking for?",
  },
  noSearchQueryBody: {
    id: "userSearch.noSearchQueryBody",
    defaultMessage: "Start typing in the Search field above",
  },
  searchFailedLine1: {
    id: "userSearch.searchFailedLine1",
    defaultMessage: "Something went wrong on our side.",
  },
  searchFailedLine2: {
    id: "userSearch.searchFailedLine2",
    defaultMessage: "We are currently working on the problem.",
  },
  searchFailedLine3: {
    id: "userSearch.searchFailedLine3",
    defaultMessage: "Please try searching again later.",
  },
  somethingWentWrong: {
    id: "userSearch.searchFailedInDropdown",
    defaultMessage: "Ooops.. something went wrong. Try again later.",
  },
  somethingWrong: {
    id: "cc_purchase_error_something_went_wrong",
    defaultMessage: "Something went wrong. Please try again.",
  },
  feedSomethingWentWrong: {
    id: "auction_bids_error_not_available",
    defaultMessage: "Something went wrong",
  },
  youFollow: {
    id: "you-follow",
    defaultMessage: "You follow",
  },
  share: {
    id: "share",
    defaultMessage: "Share",
  },
  linkCopied: {
    id: "link.copied",
    defaultMessage: "Link copied to clipboard",
  },
  tokenCopied: {
    id: "token.copied",
    defaultMessage: "Token copied to clipboard",
  },
  report: {
    id: "moderation.report",
    defaultMessage: "Report",
  },
  shareProfile: {
    id: "profile.menu.share",
    defaultMessage: "Share Profile",
  },
  reportSubmit: {
    id: "moderation.report.submit",
    defaultMessage: "Submit report",
  },
  reportSex: {
    id: "moderation.report.reason.sex",
    defaultMessage: "Sexual content",
  },
  reportViolence: {
    id: "moderation.report.reason.violence",
    defaultMessage: "Violence",
  },
  reportAbuse: {
    id: "moderation.report.reason.abuse",
    defaultMessage: "Abusive content",
  },
  reportSpam: {
    id: "moderation.report.reason.spam",
    defaultMessage: "Spam",
  },
  reportOther: {
    id: "moderation.report.reason.other",
    defaultMessage: "Other",
  },
  unfollowUserTitle: {
    id: "modal.unfollow.title",
    defaultMessage: "Unfollow",
  },
  unfollowUserQuestion: {
    id: "modal.unfollow.bodyText",
    defaultMessage: "Are you sure you want to unfollow {name}?",
  },
  reportUserTitle: {
    id: "modal.report.user.title",
    defaultMessage: "Report on",
  },
  blockUserTitle: {
    id: "moderation.block-user",
    defaultMessage: "Block User",
  },
  blockUserQuestion: {
    id: "moderation.block-user-question",
    defaultMessage: "Are you sure you want to block {name}?",
  },
  unblockUserTitle: {
    id: "moderation.unblock-user",
    defaultMessage: "Unblock User",
  },
  unblockUserQuestion: {
    id: "moderation.unblock.user.question",
    defaultMessage: "Are you sure you want to unblock {name}?",
  },
  unblock: {
    id: "moderation.unblock",
    defaultMessage: "Unblock",
  },
  blockList: {
    id: "moderation.block-list",
    defaultMessage: "Block list",
  },
  blockedUsers: {
    id: "more.item.blocked",
    defaultMessage: "Blocked Users",
  },
  manageBlockedUsers: {
    id: "modal.block.users.title",
    defaultMessage: "Manage Blocked Users",
  },
  noItems: {
    id: "no-items",
    defaultMessage: "No items",
  },
  yes: {
    id: "confirmation.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "confirmation.no",
    defaultMessage: "No",
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel",
  },
  back: {
    id: "back",
    defaultMessage: "Back",
  },
  uploadFailed: {
    id: "upload.fail",
    defaultMessage: "Upload failed",
  },
  happyMomentUnMute: {
    id: "profile.happy.moment.unmute",
    defaultMessage: "Unmute",
  },
  happyMomentMute: {
    id: "profile.happy.moment.mute",
    defaultMessage: "Mute",
  },
  continue: {
    id: "buy-coins.summary.blocked-new-window.continue",
    defaultMessage: "Continue",
  },
  edit: {
    id: "edit",
    defaultMessage: "Edit",
  },
  disconnect: {
    id: "disconnect",
    defaultMessage: "Disconnect",
  },
  // Nav
  timeline: {
    id: "page.title.timeline",
    defaultMessage: "Feed",
  },
  live: {
    id: "broadcast.live",
    defaultMessage: "Live",
  },
  forYou: {
    id: "home_fragment_for_you",
    defaultMessage: "For You",
  },
  following: {
    id: "live-feed.mode.following",
    defaultMessage: "Following",
  },
  leaders: {
    id: "topnav.leaderboard",
    defaultMessage: "Leaders",
  },
  chat: {
    id: "home.v2.chat",
    defaultMessage: "Chat",
  },
  personal: {
    id: "live-feed.mode.following",
    defaultMessage: "Following",
  },
  connectedAccounts: {
    id: "settings_connected_account_page_title",
    defaultMessage: "Connected Accounts",
  },
  remove: {
    id: "Remove",
    defaultMessage: "Remove",
  },
  set: {
    id: "set",
    defaultMessage: "Set",
  },
  manage: {
    id: "manage",
    defaultMessage: "Manage",
  },
  options: {
    id: "modal.email.options.header",
    defaultMessage: "Options",
  },
  giftFromBroadcasterSent: {
    id: "gift.from.bc.gift.sent",
    defaultMessage: "Gift successfully sent",
  },
  coins: {
    id: "topnav.coins",
    defaultMessage: "Coins",
  },
  offerExpired: {
    id: "buy-coins.promo-offers.offer-expired",
    defaultMessage: "This special offer expired.",
  },
  enjoyMore: {
    id: "buy-coins.promo-offers.enjoy-more-discounts",
    defaultMessage: "Enjoy more Tango Discounts 👇",
  },
  lowVipStatus: {
    id: "cashier.get.coins.lp.low_vip_status",
    defaultMessage: "Get {vipStatus} VIP to unlock Special Offer.",
  },
  highVipStatus: {
    id: "cashier.get.coins.lp.high_vip_status",
    defaultMessage: "The Offer is for {vipStatus} VIP.",
  },
  offerPurchased: {
    id: "buy-coins.promo-offers.purchased",
    defaultMessage: "Thanks for purchasing this Offer.",
  },
  oneTimeOfferPurchased: {
    id: "buy-coins.promo-offers.already-used",
    defaultMessage: "Thanks for purchasing this One Time Offer.",
  },
  alreadyRedeemed: {
    id: "buy-coins.promo-offers.already-redeemed",
    defaultMessage: "You already redeemed this offer.",
  },
  warningMessage: {
    id: "wink_black_viewer_first_stream_message",
    defaultMessage:
      "Please be respectful to others. Offensive language is not allowed.",
  },
  waitingForParticipants: {
    id: "waiting_for_participants",
    defaultMessage: "Waiting for participants",
  },
  failurePayment: {
    id: "buy-coins.summary.error.body.default",
    defaultMessage: "Oops! Something went wrong.",
  },
  getTheApp: {
    id: "get.the.app",
    defaultMessage: "Get the App",
  },
  paymentSettings: {
    id: "settings-payment.app.title",
    defaultMessage: "Payment Settings",
  },
  settings: {
    id: "settings",
    defaultMessage: "Settings",
  },
  broadcastPermissionsGotIt: {
    id: "broadcast-permissions.got-it",
    defaultMessage: "Got it!",
  },
  logIn: {
    id: "log.in",
    defaultMessage: "Log in",
  },
  joinTangoCommunity: {
    id: "join.tango.community",
    defaultMessage: "Join Tango Community",
  },
  signUpToChat: {
    id: "new_sign_up.header.chat.title",
    defaultMessage: "Sign up to chat",
  },
  stayConnectedWithFriends: {
    id: "stay.connected.with.friends",
    defaultMessage:
      "Stay connected with your friends anywhere and anytime! Download the app and enjoy new unique features!",
  },
  installTheApp: {
    id: "install.the.app",
    defaultMessage: "Install the App",
  },
  myProfile: {
    id: "page.title.myprofile",
    defaultMessage: "My Profile",
  },
  leftMessage: {
    id: "timer_left",
    defaultMessage: "{value} left",
  },
  customerSupport: {
    id: "header.dropdown.customer-support",
    defaultMessage: "Customer Support",
  },
  auction: {
    id: "tango_cards_auction",
    defaultMessage: "Tango Cards Auction",
  },
  subscriptions: {
    id: "header.dropdown.fan-zone",
    defaultMessage: "Fan Zone",
  },
  subscribers: {
    id: "page.title.my-fans",
    defaultMessage: "My Fans",
  },
  posts: {
    id: "posts",
    defaultMessage: "Posts",
  },
  appSettings: {
    id: "settings.app.title",
    defaultMessage: "Settings",
  },
  referralProgram: {
    id: "header.dropdown.referral-program",
    defaultMessage: "Referral Program",
  },
  agencyReferralProgram: {
    id: "header.dropdown.agency.referral.program",
    defaultMessage: "Agency Referral Program",
  },
  signUp: {
    id: "banner.agencies.button",
    defaultMessage: "Sign Up",
  },
  editProfile: {
    id: "header.dropdown.edit-profile",
    defaultMessage: "Edit Profile",
  },
  chatPlaceholder: {
    id: "say.something",
    defaultMessage: "Say something...",
  },
  myBalance: {
    id: "buy-coins.offers.my-balance",
    defaultMessage: "My balance",
  },
  profileViralityTitle: {
    id: "profile.virality.link.title",
    defaultMessage: "Check {name} profile on Tango!",
  },
  profileViralityDescription: {
    id: "profile.virality.link.description",
    defaultMessage: "Follow to receive LIVE updates!",
  },
  unblockUserText: {
    id: "profile.unblock-user-text",
    defaultMessage: "You will unblock this person.",
  },
  groupChat: {
    id: "group-chat",
    defaultMessage: "Group Chat",
  },
  broadcastEnded: {
    id: "broadcast.ended.title",
    defaultMessage: "Broadcast ended",
  },
  goLive: {
    id: "profile.404.cta",
    defaultMessage: "Go Live",
  },
  getTango: {
    id: "get.tango.cta",
    defaultMessage: "Get Tango",
  },
  cashierOfferExpired: {
    id: "cashier_offer_expired",
    defaultMessage: "Offer is not available. Here’s another offer for you!",
  },
  socialGames: {
    id: "social.games",
    defaultMessage: "Social Games",
  },
  settingsLanguage: {
    id: "settings.language",
    defaultMessage: "Language",
  },
  settingsOneClickSettings: {
    id: "profile_settings_one_click_gifting",
    defaultMessage: "One-Click Gifting ",
  },
  settingsOneClickConfirmSettings: {
    id: "settings.one-click-confirm-settings",
    defaultMessage: "Allow sending gifts without confirmation",
  },
  settingsRestore: {
    id: "settings.restore",
    defaultMessage: "You will have 30 days to restore your account",
  },
  settingsPhoneNumber: {
    id: "modal.phone-edit.placeholder",
    defaultMessage: "Phone number",
  },
  consentSettings: {
    id: "settings.consent-settings",
    defaultMessage: "Consent Settings",
  },
  settingsEmail: {
    id: "buy-coins.checkout.fawry.email",
    defaultMessage: "Email",
  },
  settingsConnect: {
    id: "connect",
    defaultMessage: "Connect",
  },
  explore: {
    id: "explore",
    defaultMessage: "Explore",
  },
  visitStreamerProfile: {
    id: "mini.profile.visit.to.view-post",
    defaultMessage: "Visit {name} profile to view this post",
  },
  vipStatus: {
    id: "vip.status",
    defaultMessage: "VIP Status",
  },
  darkTheme: {
    id: "settings_application_dark_theme",
    defaultMessage: "Dark Theme",
  },
  skip: {
    id: "welcome-onboarding-offer.skip",
    defaultMessage: "Skip",
  },
  savePrice: {
    id: "save.price",
    defaultMessage: "Save {price}",
  },
  upTo: {
    id: "social.games.up.to",
    defaultMessage: "Up to",
  },
  spinToWin: {
    id: "offers.spin-to-win",
    defaultMessage: "Spin to Win",
  },
  submit: {
    id: "submit",
    defaultMessage: "Submit",
  },
  gifts: {
    id: "gifts",
    defaultMessage: "Gifts",
  },
  showAll: {
    id: "show.all",
    defaultMessage: "Show all",
  },
  general: {
    id: "general",
    defaultMessage: "General",
  },
  other: {
    id: "moderation.report.reason.other",
    defaultMessage: "Other",
  },
  payments: {
    id: "payments",
    defaultMessage: "Payments",
  },
  privacy: {
    id: "privacy",
    defaultMessage: "Privacy",
  },
  currency: {
    id: "buy-coins.currency",
    defaultMessage: "Currency",
  },
  yesterday: {
    id: "date_format_yesterday",
    defaultMessage: "Yesterday",
  },
  today: {
    id: "date_format_today",
    defaultMessage: "Today",
  },
  moreOffers: {
    id: "more.offers",
    defaultMessage: "More Offers",
  },
  more: {
    id: "modal.send-media.gifts-carousel.more-btn",
    defaultMessage: "More",
  },
  blockedUser: {
    id: "you.blocked.this.user",
    defaultMessage: "You blocked this user",
  },
  blockedUserInChat: {
    id: "you.were.removed.from.chat",
    defaultMessage: "You were removed from chat.",
  },
  notifySelfGifting: {
    id: "notify.self.gifting",
    defaultMessage: "Gift cannot be sent to your own account.",
  },
  deleteConversation: {
    id: "chat.request.delete.message.request",
    defaultMessage: "Delete chat",
  },
  deleteConversationBody: {
    id: "chat.request.delete.message.request.body",
    defaultMessage: "Are you sure you want to delete your chat with {name}?",
  },
  viewAll: {
    id: "view.all",
    defaultMessage: "View all",
  },
  viewProfile: {
    id: "view.profile",
    defaultMessage: "View Profile",
  },
  justSomethingWentWrong: {
    id: "auction_bids_error_not_available",
    defaultMessage: "Something went wrong",
  },
  close: {
    id: "close",
    defaultMessage: "Close",
  },
  removeCardFromAuction: {
    id: "remove.card.from.auction",
    defaultMessage: "Remove Card from Auction",
  },
  removeCard: {
    id: "remove.card",
    defaultMessage: "Remove Card",
  },
  creators: {
    id: "creators",
    defaultMessage: "Creators",
  },
  supporters: {
    id: "supporters",
    defaultMessage: "Supporters",
  },
  allUsers: {
    id: "all.users",
    defaultMessage: "All Users",
  },
  newUsers: {
    id: "new.users",
    defaultMessage: "New Users",
  },
  filters: {
    id: "filters",
    defaultMessage: "Filters",
  },
});

export const benefits = Object.values(
  defineMessages({
    0: {
      id: "banner.join_tango.list.1",
      defaultMessage: "Chat with broadcasters",
    },
    1: {
      id: "banner.join_tango.list.2",
      defaultMessage: "Support broadcasters",
    },
    2: {
      id: "banner.join_tango.list.3",
      defaultMessage: "Follow your favorite streams",
    },
  })
);
export default sharedMessages;
